import { createApp, h } from "vue";
import "./assets/scss/index.scss";
import App from "./App.vue";
import CommonComponents from "./components/common/";
import Toast, { PluginOptions } from "vue-toastification";
import { ToastOptionsAndRequiredContent } from "vue-toastification/dist/types/types";
import router from "./router";
import { i18n } from "./locales";
import { createPinia } from "pinia";
import { worker } from "./mocks/browser";
import PrimeVue from "primevue/config";
import "primevue/resources/themes/lara-light-indigo/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "vue-toastification/dist/index.css";
import usePrimeVueComponents from "./composables/usePrimeVueComponents";
import ConfirmationService from "primevue/confirmationservice";
import { VueFire } from "vuefire";
import { initAmplify } from "./services/auth/auth.service";
import { firebaseApp } from "./services/firebase";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
// Sentry, see https://sentry.io/ferdia-as/ferdia-sharebus-fe/getting-started/javascript-vue/
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/browser";
import Tooltip from "primevue/tooltip";
import ElementPlus from "element-plus";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
const app = createApp({
  setup() {
    initAmplify(); //Initiating AWS amplify.
  },
  render: () => h(App),
});
CommonComponents.register(app);
usePrimeVueComponents(app);

if (import.meta.env.MODE === "mock") {
  worker.start();
}

const filterBeforeCreate = (
  toast: ToastOptionsAndRequiredContent,
  toasts: ToastOptionsAndRequiredContent[]
) => {
  if (toasts.filter((t) => t.type === toast.type).length !== 0) {
    // Returning false discards the toast
    return false;
  }
  // You can modify the toast if you want
  return toast;
};

const options: PluginOptions = {
  // You can set your default options here
  timeout: 1000,
  transition: "enter",
  filterBeforeCreate: filterBeforeCreate,
};

const isProd = import.meta.env.MODE === "production";

// /*
//  *
//  * See also :
//  * - [initial setup](https://sentry.io/ferdia-as/ferdia-sharebus-fe/getting-started/javascript-vue/)
//  * - [vue guide](https://docs.sentry.io/platforms/javascript/guides/vue/)
//  * - [todo - for further refinement and CI/CD integration](https://medium.com/techsoplaya/how-to-add-sentry-to-your-vue-js-app-and-integrate-it-into-gitlab-ci-cd-75a0f8a6faf0)
//  *
//  */
Sentry.init({
  app,
  // https://docs.sentry.io/platforms/javascript/configuration/options/#dsn
  dsn: "https://0e710114403a85f59b1930404d1ca748@o391391.ingest.sentry.io/4505686593699840",

  // https://docs.sentry.io/platforms/javascript/configuration/options/#environment
  environment: import.meta.env.MODE || "UNKNOWN", // e.g. production,

  // https://docs.sentry.io/platforms/javascript/configuration/integrations/
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),

      // https://docs.sentry.io/platforms/javascript/performance/instrumentation/automatic-instrumentation/#tracingorigins
      tracingOrigins: ["localhost", "business.ferdia.co", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: isProd ? 0.1 : 1,

  // https://docs.sentry.io/platforms/javascript/configuration/options/#debug
  debug: false,

  // Vue-specific, see https://docs.sentry.io/platforms/javascript/guides/vue/
  tracingOptions: {
    trackComponents: true,
  },
  logErrors: !isProd,
  attachProps: true,
});

/*
 * initially used for http response(success and error) handling
 */
app.use(PrimeVue);
app.use(ConfirmationService);
app.use(ElementPlus);
app.use(Toast, options);
app.use(i18n);
app.use(pinia);
app.use(router);
app.use(VueFire, {
  // imported above but could also just be created here
  firebaseApp,
});
app.directive("tooltip", Tooltip);
app.mount("#app");
